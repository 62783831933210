import React, { useEffect } from 'react'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import { FiFolder } from "react-icons/fi";
import { IoIosRocket, IoLogoGithub } from "react-icons/io";
// import { LanguageContext } from '../../contexts/LanguageContext'
import './CardProject.css'

const CardProject = ({ title, text, techs = [], url = "", github= "" }) => {


    const squareVariants = {
        visible: { opacity: 1, scale: 1, x: 0, transition: { type: 'spring', bounce: 0.4, duration: 0.8 } },
        hidden: { opacity: 0, scale: 0, x: 0 }
    };

    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, inView]);


    return (
        <motion.div 
        variants={squareVariants}
        ref={ref}
        animate={controls}
        initial="hidden"
        className="app__cardproject">

            <div className="app__cardproject_headericons">
                <FiFolder className='iconscard' />
                <div >
                    {github !== '' && <a href={github} target="_blank">
                        <button className='app__cardproject_neumorfismo'>
                            <IoLogoGithub className='iconscard_external_link' />
                        </button>
                    </a>
                    }
                    
                    {url !== '' && <a href={url} target="_blank">
                        <button className='app__cardproject_neumorfismo'>
                            <IoIosRocket className='iconscard_external_link' />
                        </button>
                    </a>
                    }
                </div>
            </div>

            <div className="app__cardproject_info">
                <h2>{title}</h2>
                <p>{text}</p>
            </div>

            <div className="app__cardproject_technologies">
                {techs.length > 0 && techs.map((item, index) => (

                    <h5 key={`h5-${index}`}>{item}</h5>
                ))}

            </div>

        </motion.div>
    )
}

export default CardProject