import React, { createContext, useState } from 'react'


 const LanguageProvider = ({ language, active, children }) => {
   
    language =   (window.localStorage.getItem('language')===null?"EN":window.localStorage.getItem('language'))
    const [currentLanguage, setCurrentLanguage] = useState(language)
    const [currentActive, setCurrentActive] = useState('#Home');

    const handleUpdateLanguage = (update) => {
       // const marged = merge({}, currentLanguage, update)
       // update = currentLanguage
       localStorage.removeItem('language') // Eliminar la sesion del lenguage actual 
       localStorage.setItem('language', update) // Colocar el nuevo lenguage
        setCurrentLanguage(update)
        
    }

    const handleActive = (update) => {
        setCurrentActive(update);
    }

    return (
        <LanguageContext.Provider
            value={{
                language: currentLanguage,
                updateLanguage: handleUpdateLanguage,
                currentActive,
                updateActive: handleActive
            }}
        >
            {children}
        </LanguageContext.Provider>
    )
}
export default LanguageProvider

export const LanguageContext  = createContext({
    language: "EN",
    active:'#Home',
    updateLanguage: () => {},
    updateActive: () => {}

})