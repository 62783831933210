import React, {useContext, useEffect} from 'react';
import { useInView } from "react-intersection-observer";

import { LanguageContext } from '../../contexts/LanguageContext'
import './Skill.css';
import { images } from '../../constants';
import { Card } from '../../components';


const Skill = () => {

  const { updateActive ,language } = useContext(LanguageContext);
  const [ref, inView] = useInView({ once: false, amount: 0.5 });

  useEffect(() => {
    if (inView) {
      updateActive('#Skills');
      // console.log('skills')
    }
  }, [inView]);


  return (
    <div
      id="Skills"
      ref={ref}
      className="app__skill section__padding"

    >
      <div className="app__skill-div" />
      <h1 className="app__skill-title">{language==="EN"?"My Skills":"Habilidades"}</h1>

      <div
        className="app__skill-cards">
        {images.imagesArr.map((item, index) => (
          <Card key={`Card-${index}`} image={item.img} title={item.text} />
        ))}
      </div>

    </div>
  )
}

export default Skill