import React, {useRef, useEffect, useContext} from 'react'
import {useAnimation, motion } from 'framer-motion'
import { useInView } from "react-intersection-observer";

import './Projects.css'
import { CardProject } from '../../components'
import { data } from '../../constants'
import { LanguageContext } from '../../contexts/LanguageContext'

const squareVariants = {
    visible: { opacity: 1, scale: 1, x:0, transition: {type: 'spring', bounce:0.4, duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 , x:0}
  };

const Projects = () => {

    const { updateActive ,language } = useContext(LanguageContext);

    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
        updateActive('#Projects')
        controls.start("visible");
        } else {
        controls.start("hidden");
        }
    }, [controls, inView]);

    return (
        <div 
        variants={squareVariants}
        ref={ref}
        animate={controls}
        initial="hidden"
        id="Projects"
        className="app__projects section__padding">

            <div className="app__projects-div" />
            <h1 className="app__projects-title">{language === "EN"?"My Projects":"Mis Proyectos"}</h1>

            <div className="app__projects-projectcards">

                {data.projectsData.map((item, index) => {

                    const { titleEN, titleES, textEN, textES, url, techs, github } = item;

                    return (
                        <CardProject
                            key={`project-${index}`}
                            title={language==="EN"? titleEN: titleES}
                            text={language==="EN"? textEN: textES}
                            url={url}
                            github={github}
                            techs={techs}
                        />
                    )
                })
                }
            </div>

        </div>
    )
}

export default Projects