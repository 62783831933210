import foto1 from '../assets/foto1.jpg'
import foto2 from '../assets/foto2.jpg'
import react from '../assets/react.svg'
import node from '../assets/node.svg'
import mysql from '../assets/mysql.svg'
import html from '../assets/html.svg'
import git from '../assets/git.svg'
import css from '../assets/css.svg'
import typescript from '../assets/typescript.svg'
import redux from '../assets/redux.svg'
import nextjs from '../assets/nextjs.svg'
import javascript from '../assets/javascript.svg'
import php from '../assets/php.svg'
import sql from '../assets/sql.svg'
import logotimeline from '../assets/logotimeline.svg'
import uth from '../assets/uth.webp'
import uthflorida from '../assets/uthflorida.png'
import resumeEN from '../assets/Resume Arles Maldonado_EN.pdf'
import resumeSP from '../assets/Resume Arles Maldonado_SP.pdf'
import altiatek from '../assets/altiatek.jpg'


const imagesArr = [
    {
        img: react,
        text: "React JS"
    },
    {
        img: nextjs,
        text: "Next.js"
    },
    {
        img: redux,
        text: "Redux"
    },
    {
        img: node,
        text: "Node JS"
    },
    {
        img: mysql,
        text: "MySql"
    },
    {
        img: html,
        text: "HTML"
    },
    {
        img: git,
        text: "Git Control" 
    },
    {
        img: css,
        text: "CSS"
    },
    {
        img: javascript,
        text: "JavaScript"
    },
    {
        img: typescript,
        text: "TypeScript"
    },
    {
        img: php,
        text: "PHP"
    },
    {
        img: sql,
        text: "SQL Server"
    }
]

export default {
    foto1,
    foto2,
    logotimeline,
    uth,
    altiatek,
    uthflorida,
    imagesArr,
    resumeEN,
    resumeSP
}