import React, {useEffect, useContext} from 'react'

import { LanguageContext } from '../../contexts/LanguageContext'
// import {useAnimation, motion } from 'framer-motion/dist/framer-motion'
import {useAnimation, motion } from 'framer-motion'
import { useInView } from "react-intersection-observer";

import './About.css'

const variants = {
    visible: { opacity: 1, scale: 1, x:[100,-100,0], transition: {type: 'spring', bounce:0.2, duration: 0.8 } },
    hidden: { opacity: 0, scale: 1 , x:200}
};

const About = () => {
  const { updateActive ,language } = useContext(LanguageContext);

  const controls = useAnimation();
  const [ref, inView] = useInView({ once: false, amount: 1 });

  useEffect(() => {
    if (inView) {
      updateActive('#About')
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
    ref={ref}
    animate={controls}
    variants={variants}
    initial="hidden"
    id="About" className="app__about section__padding">
        
        <div className="app__about-title">
          <div className="app__about-decorative-div" />
          {/* <div className="app__about-line" /> */}
          <h1 className="app__about-h1">{language==="EN"?"About Me":"Acerca de mi"}</h1>
          {/* <div className="app__about-line" /> */}
        </div>

        <div className="app__about-container">
          <div id="decorative-second" className="app__about-decorative-div" />
          <div>
            {language==="EN"
            ?<p className="app__about-p">I’m a <span>Full-Stack Developer</span> located in Honduras. I have a serious passion for <span>Web Development</span>. Skilled in handling both sides of web development, from back-end coding tasks to front-end design using the latest programming softwares. Well-organised person, problem solver, independent employee with high attention to detail. Fan of music, TV series and movies. Enthusiastic about learning new technologies, and working with dedicated teams to build efficient web applications suited to the user’s needs.</p>
            :<p className="app__about-p">Soy un <span>desarrollador Full-Stack</span> del país de Honduras. Tengo una gran pasión por el <span>Desarrollo Web</span>. Hábil en el manejo de ambos lados del desarrollo web, desde tareas de codificación de back-end hasta diseño de front-end utilizando los últimos softwares de programación. Persona bien organizada, solucionador de problemas, empleado independiente con gran atención a los detalles. Aficionado a la música, las series de televisión y el cine. Entusiasta por aprender nuevas tecnologías y trabajar con equipos dedicados para crear aplicaciones web eficientes adaptadas a las necesidades del usuario.</p>
            }
            <p style={{marginTop:"20px", color:"var(--primary-color)"}} className="app__about-p">{language==="EN"?"Let's make something special!":"¡Construyamos algo genial!"}</p>
          </div>
        </div>
    </motion.div>
  )
}

export default About