import "./styles.css";
import {Home} from "./containers/";

export default function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}
