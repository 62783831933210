import React, { useRef, useEffect, useContext } from 'react'
import { useInView } from 'framer-motion'
import { LanguageContext } from '../../contexts/LanguageContext'
import './Contact.css'

const Contact = () => {

    const { updateActive ,language } = useContext(LanguageContext);

    const ref = useRef(null);
    const isInView = useInView(ref, { once: false, amount: 0.5 });

    useEffect(() => {
       
        if(isInView)
        {
            updateActive('#Contact')
        }

    }, [isInView])

    return (
        <div
            ref={ref}
            id="Contact"
            className="app__contact section__padding">
            <h1
                style={{
                    animation: isInView ? "reveal 1s forwards 0s" : "none",
                    opacity: isInView ? 1 : 0
                }}
                className="app__contact_title"
            >
                {language === "EN"
                ?"I'm always looking for new Opportunities."
                :"Siempre estoy buscando nuevas oportunidades."
                }
            </h1>

            <div style={{ marginTop: '20px' }}>
                {/* <p style={{ '--delay': '0s' }}>Do you have any question or want some help with something?</p>
                <p style={{ '--delay': '.15s' }}>Send an email and <span>let's get in touch.</span></p> */}
                <p
                style={{
                    animation: isInView ? "reveal 0.5s forwards 0.15s" : "none",
                    opacity: isInView ? 1 : 0,
                }}> 
                    {language==="EN"
                    ?"Do you have any question or want some help with something?"
                    :"¿Tienes alguna pregunta o necesitas ayuda con algo? "
                    }
                </p>

                <p style={{
                    animation: isInView ? "reveal 0.5s forwards 0.30s" : "none",
                    opacity: isInView ? 1 : 0,
                }}>
                    {language==="EN"?"Send an email and":"Enviame un correo y"} <a href="mailto: arles_mald@hotmail.com"><span>{language==="EN"?"let's get in touch.":"pongamonos en contacto."}</span></a>
                </p>
            </div>
        </div>
    )
}

export default Contact