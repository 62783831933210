import React, { useState, useContext } from "react";
import { LanguageContext } from '../../contexts/LanguageContext'
import "./Navbar.css";
import { GiHamburgerMenu } from 'react-icons/gi'
import { FiX } from "react-icons/fi";
import { FaLinkedin, FaGithub, FaEnvelope, FaDownload } from "react-icons/fa";
// import { CustomizedButton } from '../index'
import { data,images } from "../../constants";

const Navbar = () => {

  const { currentActive, updateActive, language, updateLanguage } = useContext(LanguageContext);

  const profile = {
    color: 'black',
    fontFamily: 'var(--font-base)',
    fontSize: '1.4rem',
    marginTop: '25px'
    // padding:'0px 5px 0px 5px'
    // paddingLeft:'5px',
    // paddingRight:'5px'
  }




  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <nav className="app__navbar">
      {/* Logo */}

      <div className="logo">
        <div id="f">
          <span style={{ color: "black" }}>{`<`}</span>
          <a href="/">Arles</a>
          {/* <span style={{ color: "#ffad01", fontSize: '3rem' }}>{`.`}</span> */}
          <span style={{ color: '#ffad01' }}>{` /`}</span>
          <span style={{ color: "black  ", align: 'start', height: '100%' }}>{`>`}</span>
        </div>
      </div>

      {/* <div>
          <span>{`<`}</span>
          <a href="/">Arles</a>
          <span style={{ color: "#ffad01", fontSize: '60px' }}>.</span>
          <span style={{fontSize:'2rem', marginBottom:'2px'}}>{`/`}</span>
          <span>{`>`}</span>
        </div>
      </div> */}

      <div>
        <ul className="app__navbar_links">
          {data.smallMenu.map((item, index) => (
            <li key={`menu-${index}`}>
              <a onClick={() => { updateActive(item.url) }} href={item.url}>{language === "EN" ? item.nameEn : item.nameEs}</a>
            </li>
          ))}

        </ul>
      </div>

      <div className="app__navbar_button">
        {/* <button>Contact me</button> */}
        {/* <CustomizedButton title="Contact Me" /> */}
        <select onChange={(event) => updateLanguage(event.target.value)} defaultValue={language} className='app__navbar-select' name="language" id="cars">
          <option value="EN" >{language === "ES" ? "Inglés" : "English"}</option>
          <option value="ES" >{language === "ES" ? "Español" : "Spanish"}</option>
        </select>
      </div>



      {/* Menu for mobile devices */}
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#404040" fontSize={27} onClick={() => setToggleMenu(true)} />

        {toggleMenu && (

          <div className="app__navbar-smallscreen_overlay slide-bottom">

            <div className='app__navbar-smallscreen_overlay_header'>

              <div className="logo_movil">

                <span style={{ fontSize: '1.6rem', color: "black" }}>{`<`}</span>
                <a style={{ fontSize: '1.6rem' }} href="/">Arles</a>
                {/* <span style={{ color: "#ffad01", fontSize: '3rem' }}>{`.`}</span> */}
                <span style={{ fontSize: '1.6rem', color: '#ffad01' }}>{` /`}</span>
                <span style={{ fontSize: '1.6rem', color: "black  ", align: 'start', height: '100%' }}>{`>`}</span>


              </div>


              <div className="app__navbar_movil_rightside">
                <select onChange={(event) => updateLanguage(event.target.value)}defaultValue={language} style={{ border: '1px solid var(--primary-color)', borderRadius: '30px', outline: '0', background: 'white', color: '#404040', fontFamily: 'var(--font-base)', height: '25px', width: '70px' }} className='overlay__language'>
                  <option value="EN" >{language === "EN" ? "Inglés" : "English"}</option>
                  <option value="ES" >{language === "ES" ? "Español" : "Spanish"}</option>
                </select>
                <FiX fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
              </div>

            </div>


            <div className="app__navbar-smallscreen_info">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '110px', height: '110px' }}>
                <div className="avatar_decoration" />
                <div className="app__navbar_smallscreen_avatar">
                  <h2>AM</h2>
                </div>
              </div>

              <h2 style={{ marginBottom: '5px' }}>Arles Maldonado</h2>

              <h5 className="app__navbar-smallscreen_dev">{language === "EN" ? "Web Developer" : "Desarrollador Web"}</h5>

              <div className="app__navbar_smallscreen_social">
                <a href="https://www.linkedin.com/in/arles-maldonado/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin style={{ fontSize: '1.5rem', color: 'var(--secondary-color)', cursor: 'pointer' }} />
                </a>
                <a href="https://github.com/arlesmald" target="_blank" rel="noopener noreferrer">
                  <FaGithub style={{ fontSize: '1.5rem', color: 'var(--secondary-color)', cursor: 'pointer' }} />
                </a>
              </div>

              <div className="app__navbar_smallscreen_labels">
                <div>
                  <h5>4+</h5>
                  <h6>{language === "EN" ? "Years of work" : "Años experiencia"}</h6>
                </div>
                <div>
                  <h5>8+</h5>
                  <h6>{language === "EN" ? "Projects" : "Proyectos"}</h6>
                </div>
                <div>
                  <h5>5+</h5>
                  <h6>{language === "EN" ? "Code skills" : "Habilidades"}</h6>
                </div>
              </div>

              <div className="app__navbar_smallscreen_contact">
                <a href={language === "EN" ?images.resumeEN : images.resumeSP} target="_blank" rel="noopener noreferrer">
                  <button className="app__navbar_smallscreen_button">{language === "EN" ? `Download CV ` : `Descargar CV `}<span><FaDownload /></span></button>
                </a>
                <div className="app__navbar_smallscreen_roundedbutton">
                  <a href="mailto: arles_mald@hotmail.com">
                    <FaEnvelope fontSize="1.2rem" color="gray" />
                  </a>
                </div>
              </div>

              <div style={profile}>
                <h3 style={{ paddingLeft: '30px', paddingRight: '30px', marginBottom: '0' }}>Menu</h3>
                <div style={{ height: '0px', borderRight: '20px solid transparent', borderLeft: '20px solid transparent', borderTop: '5px solid var(--primary-color)' }} />
              </div>

              <div className={`app__navbar_smallscreen_menu`}>
                {data.smallMenu.map((item, index) => (
                  <div key={`item_menu_${index}`} onClick={() => { updateActive(item.url); setToggleMenu(false) }} style={{ backgroundColor: currentActive === item.url ? 'var(--primary-color)' : 'Transparent' }} className="app__navbar_smallscreen_roundedbutton">
                    <a href={item.url}>
                      <item.icon style={{ fontWeight: '900' }} color="white" fontSize="1.3rem" />
                    </a>
                  </div>
                ))}
              </div>

            </div>

          </div>
        )}
      </div>

    </nav>
  );
};

export default Navbar;
