import React, { useEffect, useContext } from 'react'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from "react-intersection-observer";

import { Chrono } from "react-chrono";
import { CardTimeLine } from '../../components/';
import { images } from '../../constants';
import { LanguageContext } from '../../contexts/LanguageContext'

import './Experience.css'


const Experience = () => {

  const { updateActive, language } = useContext(LanguageContext);

  const controls = useAnimation();
  const [ref, inView] = useInView({ once: false, amount: 0.5 });

  const variants = {
    visible: { opacity: 1, scale: 1, transition: { x: 0, delay: 0.2 } },
    hidden: { opacity: 0, scale: 0, x: 0 }
  };

  useEffect(() => {
    if (inView) {
      updateActive('#Experience')
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      id="Experience"
      animate={controls}
      variants={variants}
      initial="hidden"
      className="app__experience section__padding">

      <div className="app__experience-title">
        {/* <SubHeading title="Work Experience" /> */}
        <div className="app__experience-div" />
        {language === "EN" 
        ?
          <>
            <h1 className="app_experience-h1">Work</h1>
            <h1 className="app_experience-h1">Experience</h1>
          </>
          :
          <>
          <h1 className="app_experience-h1">Experiencia</h1>
            <h1 className="app_experience-h1">Laboral</h1>
          </>
        }

        {language==="EN"
        ?<p id="paragraph">Over the years of my work experience I have acquired a wide variety of skills as a web developer with which I have been able to solve problems and at the same time create new things. And of course, I am always learning new technologies to be able to offer solid solutions when it comes to web development.</p>
        :<p id="paragraph">Durante los años de mi experiencia laboral he adquirido una gran variedad de habilidades como desarrollador web con las cuales he sido capaz de resolver problemas y al mismo tiempo crear nuevas cosas. Y por supuesto, siempre sigo aprendiendo tecnologías nuevas para ser capaz de ofrecer soluciones sólidas cuando se trata de desarrollo web.</p>
        }
      </div>

      <div className="app__experience-timeline">
        <div id="timeline">
          <Chrono mode="VERTICAL" theme={{
            primary: '#f8ce31',
            secondary: 'white',
            // cardBgColor: '#eff0f0',
            cardBgColor: '#ffffff',
            cardTitle: 'black',
            // cardForeColor: '#f8ce31',
            titleColor: 'gray',
            titleColorActive: 'black',
          }}
          >

            <CardTimeLine
              title={"AltiaTek"}
              subtitle={language==="EN"?"Technical Consultant (Current)":"Consultor Técnico (Actual)"}
              text={language === "EN"
              ?"As a technical consultant part of an agile team I have the responsiblity of creating or developing custom solutions starting from initial requirements to actually deliver a product of quality that meets the client's expectations."
              :"Como consultor técnico parte de un equipo ágil, tengo la responsabilidad de crear o desarrollar soluciones personalizadas a partir de los requisitos iniciales para entregar un producto de calidad que cumpla con las expectativas del cliente."
              }
              image={images.altiatek}
              height="90px"
              width='115px'
            />

            <CardTimeLine
              title={"Universal Technological Heritage of Florida University"}
              subtitle={language==="EN"?"Web Developer (2019 - 2022)":"Desarrollador Web (2019 - 2022)"}
              text={language === "EN"
              ?"As a Full-Stack developer I was in charge of maintaining the institution's web platforms in optimal conditions and without interruptions in the service to the users. I developed new functionalities for both platforms the insitution currently has and new modules for the institution's ERP system and in the same way I used to manage the relational database, for instance, create stored procedures and functions."
              :"Como desarrollador Full-Stack, era el encargado de mantener en óptimas condiciones las plataformas web de la institución y sin interrupciones en el servicio hacia los usuarios. Cree funcionalidades nuevas en las 2 plataformas que se manejan y módulos nuevos para el sistema ERP de la institución y de igual manera manejaba la base de datos relacional, por ejemplo, crear procedimientos almacenados y funciones."
              }
              image={images.uthflorida}
              height="115px"
              width='85px'
            />

            <CardTimeLine
              title={language==="EN"?"Technological University Of Honduras":"Universidad Tecnológica de Honduras"}
              subtitle={language==="EN"?"Web Developer (2017 - 2022)":"Desarrollador Web (2017 - 2022)"}
              text={language === "EN"
                ?"As a web developer I had the tasks of resolving errors in web platforms, creating new functionalities, in the same way it was my job to obtain information requested by users, from the relational database."
                :"Como desarrollador web tenia las tareas de resolver errores en las plataformas web, crear nuevas funcionalidades, de igual manera era mi trabajo obtener información solicitada por los usuarios, desde la base de datos relacional."
              }
              image={images.uth}
              width="110px"
              height='85px'
            />
            <div className="chrono-icons">
              <img src={images.logotimeline} alt="image2" />
              <img src={images.logotimeline} alt="image1" />
              <img src={images.logotimeline} alt="image1" />
            </div>
          </Chrono>
        </div>
      </div>

    </motion.div>
  )
}

export default Experience