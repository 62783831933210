import React, { useRef, useEffect } from 'react'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from "react-intersection-observer";

import './Card.css'

const Card = ({ image, title }) => {

  const squareVariants = {
    visible: { opacity: 1, scale: 1, x: 0, transition: { type: 'spring', bounce: 0.4, duration: 0.8 } },
    hidden: { opacity: 0, scale: 0, x: 0 }
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      variants={squareVariants}
      ref={ref}
      animate={controls}
      initial="hidden"
      className="card-container-outerdiv">

      <div className="card-container">
        <img width="50%" src={image} alt="" />
        <h3>{title}</h3>
      </div>
    </motion.div>
  )
}

export default Card