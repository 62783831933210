import {FaBriefcase, FaHome, FaAddressBook, FaMale, FaRocket} from "react-icons/fa";
import { BsFillBookmarkStarFill } from "react-icons/bs";

const projectsData = [
    {
        titleEN:'E-Commerce with Sanity',
        textEN:'This is a nice and complete ecommerce developed with some of the latest web technologies, also is ready to make payments with Stripe.',
        titleES:'E-Commerce con Sanity',
        textES:'Este es un bonito y completo ecommerce desarrollado con algunas de las últimas tecnologías web, además está preparado para realizar pagos con Stripe.',
        url:'https://ecommerce-sanity-c041g0m1s-arlesmald.vercel.app/',
        github: '',
        techs:['React.js', 'Next.js', 'Stripe','CSS','HTML']
    },
    {
        titleEN:'Sales Dashboard',
        textEN:'A Beautiful sales dashboard completely responsive ready to be used in any business. Developed with React and styled with Tailwind CSS.',
        titleES:'Dashboard de Ventas',
        textES:'Un hermoso panel de ventas completamente responsivo listo para ser utilizado en cualquier negocio. Desarrollado con React y diseñado con Tailwind CSS.',
        url:'https://sales-dashboard-arlesmald.vercel.app/',
        github: '',
        techs:['React.js', 'Tailwind CSS','HTML','CSS']
    },
    {
        titleEN:'Descentralized App',
        textEN:'A stunning descentralized web app connected to the blockchain created with some of the latest cutting edge technologies where people can send Ethereums.',
        titleES:'App Descentralizada',
        textES:'Una impresionante aplicación web descentralizada conectada a blockchain, creada con algunas de las últimas tecnologías de vanguardia donde las personas pueden enviar Ethereum.',
        url:'https://solidity-web3-0-j6go3wguk-arlesmald.vercel.app/',
        github: '',
        techs:['React.js', 'Tailwind CSS','HTML','CSS','Solidity']
    }
    ,
    {
        titleEN:'Bakery Web Page',
        textEN:'This is a cool prototype web page created for a bakery. It was developed using React Js,with pure CSS and it is fully responsive',
        titleES:'Web para panadería',
        textES:'Esta es una página web prototipo genial creada para una panadería. Fue desarrollado utilizando React Js, con CSS puro y es totalmente responsiva.',
        url:'https://bakery-page.netlify.app',
        github: '',
        techs:['React.js','HTML','CSS']
    }
    ,
    {
        titleEN:'Music Player',
        textEN:'Music player totally responsive. And it was developed using cutting edge web technologies.',
        titleES:'Reproductor de música',
        textES:'Reproductor de música totalmente responsivo. Fue desarrollado con las tecnologias web más nuevas actualmente.',
        url:'https://armald-music-player.netlify.app/',
        github: 'https://github.com/arlesmald/PlayerFy',
        techs:['React.js','Redux','Tailwind CSS']
    }
    ,
    {
        titleEN:'Rest Server',
        textEN:'We have here a complete rest server developed with NodeJS and a framework for NodeJS called ExpressJS.',
        titleES:'Servidor Rest',
        textES:'Tenemos aquí un servidor restful desarrollado con NodeJS y con el framework ExpressJS para NodeJs.',
        url:'',
        github: 'https://github.com/arlesmald/basic_node_restserver',
        techs:['NodeJS', 'Express.js','MongoDB']
    }
    ,
    {
        titleEN:'Videos Social Media',
        textEN:'This a great app to share videos, built with React and NextJS and tailwind CSS for the styling and Sanity as backend.',
        titleES:'Videos Red Social',
        textES:'Esta es una gran aplicación para compartir videos, creada con React y NextJS y Tailwind CSS para el estilo y Sanity como backend.',
        url:'https://video-social-media.vercel.app/',
        github: 'https://github.com/arlesmald/video-social-media',
        techs:['React.js', 'Next.js','Sanity', 'TailwindCSS']
    }

]

const smallMenu = [
    {
      url: '#Home',
      nameEn: 'Home',
      nameEs: 'Inicio',
      icon: FaHome,
    },
    {
      url: '#Skills',
      nameEn: 'Skills',
      nameEs: 'Habilidades',
      icon: BsFillBookmarkStarFill,
    },
    {
      url: '#About',
      nameEn: 'About',
      nameEs: 'Acerca',
      icon: FaMale,
    },
    {
      url: '#Experience',
      nameEn: 'Experience',
      nameEs: 'Experiencia',
      icon: FaBriefcase
    },
    {
      url: '#Projects',
      nameEn: 'Projects',
      nameEs: 'Proyectos',
      icon: FaRocket,
    },
    {
      url: '#Contact',
      nameEn: 'Contact',
      nameEs: 'Contacto',
      icon: FaAddressBook,
    },
  ]

export default {
    projectsData,
    smallMenu
}