import React, { useContext, useEffect } from 'react'

import { useInView } from "react-intersection-observer";
import { motion } from 'framer-motion'
import { LanguageContext } from '../../contexts/LanguageContext'
import './Header.css'
import { images } from '../../constants/'
import { CustomizedButton } from '../../components'
import { FaLinkedin, FaGithub } from "react-icons/fa";

const Header = () => {

  const { updateActive, language } = useContext(LanguageContext);

  const [ref, inView] = useInView({ once: false, amount: 0.1 });

  useEffect(() => {
    if (inView) {
      updateActive('#Home')
      // console.log('Home')
    }

  }, [inView]);

  return (
    <div ref={ref} id="Home" className="app__header">

      <motion.div initial={{ opacity: 1 }} animate={{ x: [-200, 90, 0] }} className="app__header_left_content">
        <h1 className="app__header-title">{language === "EN" ? "Hi! I Am" : "¡Hola! Soy"}</h1>
        <h1 className="app__header-name">Arles Maldonado</h1>
        <h1 className="app__header-developer">{language === "EN" ? "Web Developer" : "Desarrollador Web"}</h1>

        <p>
          {language === "EN"
            ? "A passionate Full-Stack web developer having 5+ years of experience in this technology field, so if you need any help to fix a problem or creating some cool stuff don't hesitate to get in touch with me. I would be more than happy to help you."
            : "Un desarrollador web Full-Stack apasionado, con 5+ años de experiencia en este campo de la tecnología, por lo que si necesitas ayuda para solucionar un problema o crear cosas interesantes, no dudes en ponerse en contacto conmigo. Estaré más que feliz de ayudarte."
          }
        </p>

        <div id="buttons" style={{ display: "flex", width: '100%' }}>
          <a href={language === "EN" ?images.resumeEN : images.resumeSP} target="_blank" rel="noopener noreferrer">
            <button className="app__header_button">{language === "EN" ? "Download Resume" : "Descargar CV"}</button>
          </a>
          <a href="mailto: arles_mald@hotmail.com">
            <CustomizedButton title={language === "EN" ? "Contact Me" : "Contáctame"} />
          </a>
        </div>

        <div className="app__header_social_media">
          <a className="app__header_neumorfismo" href="https://www.linkedin.com/in/arles-maldonado/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin style={{ fontSize: '1.5rem', color: '#448cd4', cursor: 'pointer' }} />
          </a>
          <a className="app__header_neumorfismo" href="https://github.com/arlesmald" target="_blank" rel="noopener noreferrer">
            <FaGithub style={{ fontSize: '1.5rem', color: '#404040', cursor: 'pointer' }} />
          </a>
        </div>

      </motion.div>

      <motion.div initial={{ opacity: 1 }} animate={{ x: [600, 0] }} transition={{ duration: 0.5 }} className="app__header_right_content">
        <img width='90%' src={images.foto1} alt="" />
      </motion.div>

    </div>
  )
}

export default Header