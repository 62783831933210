import React from 'react'
import { Navbar } from '../../components'
import LanguageProvider from '../../contexts/LanguageContext'



import { Header, Skill, About, Experience, Projects, Footer, Contact } from '../../containers'

const Home = () => {
  return (
    <div style={{width:'100%', height:'100%', marginTop:'3rem'}}>
      <LanguageProvider>
        <Navbar />
        <Header />
        <Skill />
        <About />
        <Experience />
        <Projects />
        <Contact />
        <Footer />
      </LanguageProvider>
    </div>
  )
}

export default Home