import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className="app__footer section__padding">
        <span>Designed and built by Arles Maldonado</span>
    </div>
  )
}

export default Footer