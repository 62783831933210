import React from 'react'
import { images } from '../../constants'
import './CardTimeLine.css'

const CardTimeLine = ({title, subtitle, text, image, height="100px", width="100px"}) => {
    return (
        <div className="app__cardtime">
            <div className="app__cardtime_wrapper">
                <div className="app__cardtime_headerinfo">
                    <h3 className="app__cardtime_title">{title}</h3>
                    <h4 className="app__cardtime_subtitle">{subtitle}</h4>
                </div>

                <div className="app__cardtime_img">
                    <div style={{height:height, width:width}}>

                        <img height="100%" width="100%" src={image} alt="" />
                    </div>
                </div>
            </div>
            <p className="app__cardtime_p">{text}</p>
        </div>
    )
}

export default CardTimeLine